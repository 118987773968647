/*
 * @Description: 管理后台菜单路由
 * @Autor: WangYuan
 * @Date: 2021-05-19 09:49:33
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-10-08 16:34:09
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/pages/login'),
	},
	{
		path: '/managet',
		name: 'managet',
		component: () => import('@/pages/managet'),
	},
	{
		path: '/schema',
		name: 'schema',
		component: () => import('@/pages/schema'),
	},
	{
		path: '/mall',
		name: 'mall',
		component: () => import('@/pages/mall'),
		redirect: '/mall/pages-manage',
		children: [{
				path: 'pages-manage',
				name: 'pages-manage',
				component: () => import('@/pages/mall/pages-manage'),
			},
			{
				path: 'page-build',
				name: 'page-build',
				component: () => import('@/pages/mall/page-build'),
			},
			{
				path: 'goods',
				name: '低代码商品管理',
				component: () => import('@/pages/mall/goods'),
				redirect: '/mall/goods/goods-manager',
				children: [{
						path: 'goods-manager',
						name: 'goods-manager',
						component: () => import('@/pages/mall/goods/goods-manager/index'),
					},
					{
						path: 'goods-edit',
						name: 'goods-edit',
						component: () => import('@/pages/mall/goods/goods-manager/edit'),
					},
					{
						path: 'group-manager',
						name: 'group-manager',
						component: () => import('@/pages/mall/goods/group-manager/index'),
					},
				]
			},
			{
				path: 'news',
				name: '新闻类管理页面',
				component: () => import('@/pages/mall/news'),
				redirect: '/mall/news/news-manager',
				children: [{
						path: 'news-manager',
						name: 'news-manager',
						component: () => import('@/pages/mall/news/news-manager/index'),
					},
					{
						path: 'news-edit',
						name: 'news-edit',
						component: () => import('@/pages/mall/news/news-manager/edit'),
					},
					{
						path: 'group-manager',
						name: 'group-manager',
						component: () => import('@/pages/mall/news/group-manager/index'),
					},
				]
			},
			{
				path: 'form',
				name: '表单管理页面',
				component: () => import('@/pages/mall/form'),
				redirect: '/mall/form/form-manager',
				children: [{
						path: 'form-manager',
						name: 'form-manager',
						component: () => import('@/pages/mall/form/form-manager/index'),
					},
					{
						path: 'form-edit',
						name: 'form-edit',
						component: () => import('@/pages/mall/form/form-manager/edit'),
					},
				]
			},			
			{
				path: 'store',
				name: 'store',
				component: () => import('@/pages/mall/store'),
				redirect: '/mall/store/navigation-tpl',
				children: [{
						path: 'navigation-tpl',
						name: 'navigation-tpl',
						component: () => import('@/pages/mall/store/navigation-tpl'),
					},
					{
						path: 'search-tpl',
						name: 'search-tpl',
						component: () => import('@/pages/mall/store/search-tpl'),
					},
					{
						path: 'me-tpl',
						name: 'me-tpl',
						component: () => import('@/pages/mall/store/me-tpl'),
					},
					{
						path: 'login-tpl',
						name: 'login-tpl',
						component: () => import('@/pages/mall/store/login-tpl'),
					},
					{
						path: 'category-tpl',
						name: 'category-tpl',
						component: () => import('@/pages/mall/store/category-tpl'),
					},
					{
						path: 'list-tpl',
						name: 'list-tpl',
						component: () => import('@/pages/mall/store/list-tpl'),
					},
					{
						path: 'modeSelect-tpl',
						name: 'modeSelect-tpl',
						component: () => import('@/pages/mall/store/modeSelect-tpl'),
					},
					{
						path: 'agreement-tpl',
						name: 'agreement-tpl',
						component: () => import('@/pages/mall/store/agreement-tpl'),
					},
				]
			},
			{
				path: 'model-manage',
				name: 'model-manage',
				component: () => import('@/pages/mall/model-manage'),
			},
			{
				path: 'setting-manage',
				name: 'setting-manage',
				component: () => import('@/pages/mall/setting-manage'),
			},
		]
	}
]

const router = new VueRouter({
	linkActiveClass: 'active',
	base: process.env.BASE_URL,
	routes
})

// 全局路由守卫鉴权
router.beforeEach((to, from, next) => {
	//console.log("全局路由守卫鉴权");
	if (to.name == 'login') {
		next()
	} else {
		if (!store.getters.token) {
			next('/')
		} else {
			next()
		}
	}
})

export default router