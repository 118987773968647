<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-08-19 15:10:03
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-11 11:48:56
-->
<template>
	<div class="flex flex-wrap">
		<div v-for="(item,key) in list" class="flex-center h45 w45 mr10 mb10 shadow">
			<img class="w-100 h-100" :src="item.cover" />
		</div>
		<div class="flex-center h45 w45 shadow pointer" @click="add">
			+
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	// import {
	// 	getGoodsByIds
	// } from "@/api/goods";
	import {
		RequestApi
	} from "@/api/kdzapi";


	export default {
		name: "KdzGoodsConfigList",
		props: ["value"],

		data() {
			return {
				mValue: [],
				list: [],
			};
		},

		computed: {
			...mapGetters(["project"]),
		},

		watch: {
			value: {
				immediate: true,
				deep: true,
				handler(newValue, oldValue) {
					this.getList();
				},
			},
		},

		methods: {
			// 根据this.value获取客多赞商品的图片显示在工具栏上
			async getList() {
				console.log('项目配置信息==========================>', this.project);
				let uri = this.project.config.kdzWptUrl +"/tools/post.ashx"; 
				//以参数配置的为准   "http://xcx03.keduozan.com/tools/post.ashx";
				//uri = "http://localhost:6379/tools/post.ashx";

				var param = {
					act: "get_index_spbm",
					code: '86C64761F192EA595591B4D473E87F0C',
					s_manager: this.project.config.kdzAppId,
					rule: '商品信息',
					cateid: this.value.join(","), // JSON.stringify(this.value), //cateid ,可以分割
					key: '' //查询搜索关键字
				};
				var res = await RequestApi.post(uri, param);
				console.log('登录返回->', res);
				if (res.status == "1") {
					this.list = res.data_lowcode;
				}

				// let data = {
				// 	projectId: this.project.id,
				// 	ids: this.value,
				// };

				// let {
				// 	status,
				// 	list
				// } = await getGoodsByIds(data);
				// //console.log(this.value,list);
				// if (status == "10000") {
				// 	this.list = list;
				// }

			},

			add() {
				this.$emit("edit");
			},
		},
	};
</script>