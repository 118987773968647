/*
 * @Description: 接口地址
 */
export default {
	//baseApi: 'http://127.0.0.1:3000/',  // 开发 后端接口地址
	//viewUrl: 'http://localhost:8080/#/'          ,// 开发 iframe嵌套的H5地址

	baseApi: 'https://h5.5izg.bj.cn/', //生产 后端接口地址   在nginx做了映射 https转到https://h5.5izg.bj.cn:3000/
	//baseWptApi: 'http://localhost:6379/',
	//baseApi: 'http://localhost:6379/',
	//viewUrl: 'https://mall06.keduozan.com/#/', // iframe嵌套的H5地址,客多赞服务器  用于后台设计页面的时候直观的展示实际在前端显示的效果 https://h5.5izg.bj.cn/#/
	viewUrl: 'https://mall12.5izg.bj.cn/#/',



	// baseApi: 'http://www.sunmao-design.top/mall-cook/api/', // 后端接口地址
	// viewUrl: 'http://139.196.223.93:4001/#/', // iframe嵌套的H5地址,本地开发可改为本地启动的H5地址
}