/*
 * @Description: template 类型组件对应  如果新添加一个type 要在这里配置对应打开的组件目录
 * 所有的控件属性在这里定义,不同的名字对应不同的页面
 * 重要配置  如果有新的物料类型必须在这里等级一下
 */
export default {
	number: "SchemaNumber",
	string: "SchemaString",
	strings: "SchemaMultiLineString",
	select: "SchemaSelect",
	switch: "SchemaSwitch",
	color: "SchemaColor",
	upload: "SchemaUpload",
	jump: "SchemaJump",
	time: "SchemaTime",
	cube: "SchemaCapCube",
	goods: "SchemaGoods",
	kdzgoods: "SchemaKdzGoods",
	news: "SchemaNews",
	richtxt: "SchemaRichTxt",
	object: 'SchemaObject',
	array: 'SchemaArray'
}