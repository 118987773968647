import Vue from 'vue'
import global from '@/config/global'

export default async (file) => new Promise((resolve, reject) => {
	//图片上传  后期改为阿里云oss
	//console.log('图片上传---->', global.baseApi);
	Vue.prototype.$POST.upload(global.baseApi + 'upload', ({
		file,
	}), {
		baseURL: '',
		timeout: 20000,
	}).then(res => {
		console.log('后期改为阿里云oss,图片上传返回---->', res.data);
		resolve(res.data)
	}).catch(e => {
		reject(e)
	})
})